//THIS FILE IS FOR AUTH REQUIRED ROUTES
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// private page routing
const BalanceInquiry = Loadable(lazy(() => import('views/private/balance_inquiry')));
const Appointments = Loadable(lazy(() => import('views/private/appointments')));
const AccountManagement = Loadable(lazy(() => import('views/private/account_management')));
const OnlineConsumption = Loadable(lazy(() => import('views/private/online_consumption')));
const NewServiceRequests = Loadable(lazy(() => import('views/private/new_services_requests')));
const ConsumptionSimulator = Loadable(lazy(() => import('views/private/consumption_simulator')));
const MyData = Loadable(lazy(() => import('views/private/my_data')));
const FacelecForm = Loadable(lazy(() => import('views/facelec')));
const DownloadEFactForm = Loadable(lazy(() => import('views/download_efacelec')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '',
    element: (
         <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'consulta_pago_de_facturas',
            element: <BalanceInquiry />
        }
        ,
        {
            path: '/citas',
            element: <Appointments />
        },
        {
            path: '/gestiones',
            element: <AccountManagement />
        },
        {
            path: '/consumo_en_linea',
            element: <OnlineConsumption />
        },
        {
            path: '/solicitud_nuevos_servicios',
            element: <NewServiceRequests />
        },
        {
            path: '/simulador_de_consumo',
            element: <ConsumptionSimulator />
        },
        {
            path: '/mis_datos',
            element: <MyData />
        },
        {
            path: '/factura_electronica',
            element: <FacelecForm />
        }
        ,
        {
            path: '/descarga_factura_electronica',
            element: <FacelecForm />
        },
        
    ]
};

export default MainRoutes;
